/* @import '~antd/dist/antd.css'; */
@import './assets/antd.css';
* {
  box-sizing: border-box;
}

.logo {
  font-size: 22px;
  font-weight: bold;
  color: red;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-right: 20px;
  /* padding-top: 2px; */
}

.logo img {
  margin-top: -2px;
}

header {
  z-index: 10;
  border-bottom: 2px solid #f0f0f0;
}

header.ant-layout-header {
  background-color: #f0f0f0;
  position: fixed;
  width: 100%;
  padding: 0;
}

main.ant-layout-content {
  padding: 16px 16px 0 24px;
  margin-top: 197px;
  min-height: 380px;
  background: #fff;
}

ul.ant-menu {
  background-color: #f0f0f0;
}

/* .ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
} */

.nav-container {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}

.app-user {
  padding-left: 1.4em;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.app-user__name {
  padding-right: 16px;
}

.badge {
  vertical-align: middle;
  background-color: rgb(195, 195, 195);
  margin-left: 0.75em;
  position: relative;
  top: auto;
  border-radius: 2rem;
  font-size: 65%;
  font-weight: 400;
  padding: 2px 8px;
  background: #108ee9;
  color: white;
}

@media (min-width: 768px) {
  header.ant-layout-header {
    height: 66px;
    padding: 0 16px;
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .menu-container {
    display: flex;
    flex-direction: row;
  }

  .menus {
    background-color: #f0f0f0;
  }

  main.ant-layout-content {
    margin-top: 64px;
  }
}

.site-description-item-profile-wrapper {
  /* margin-bottom: -8px; */
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.event-title {
  font-size: 1.4em;
  margin-top: -0.4em;
  /* margin-bottom: 0.5em; */
}

.ant-row {
  margin-bottom: 8px;
}

.image-preview {
  height: auto;
  width: 200px;
  object-fit: cover;
  /* border-radius: 50%; */
  margin-bottom: 1em;
}

.requiredItem {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.two-rows-label .ant-form-item-label {
  white-space: normal;
}

.ql-editor {
  min-height: 160px;
  font-size: 14px;
}

.notes {
  font-style: italic;
  max-width: 70%;
  margin: auto;
  padding-bottom: 2em;
}

.federated-button-only {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.federated-button-only .sc-amplify-sign-in-button {
  padding: 0.5rem 1rem !important;
}

.div-sm {
  padding-bottom: 0.2rem;
}

.div-sm:last-child {
  padding-bottom: 0;
}

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-title {
  margin-right: auto;
}

.ql-editor {
  font-family: 'Tw Cen MT', sans-serif !important;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: rgba(0, 0, 0, 0.35);
}

#basic_summary,
#basic_title,
#basic_moreInfo {
  letter-spacing: 0.03em;
}
